import React from "react";
import CandidateSidebar from "../Components/CandidateSidebar";
import acedemia1 from '../images/research1.jpeg'
import AcademiaSidebar from "../Components/AcademiaSidebar";

const AcedemiaPostjob =() =>{
    const job = [{
        poster:acedemia1,
        name:'MAth Researcher',
        acedmic:"Engineering College Of London",
        info:"This is For the vacany detail"
    }]
    return(
        <>
        <AcademiaSidebar/>
        <div class="job-div">
   	<div class="job-div2">
   		
  	<form class="form-post">
  		<h3>Create A Job</h3>
  		<input type="text" required placeholder="Enter Post Tittle" />
  		<input type="text" required placeholder="Enter Description" />
  		<input type="text" required placeholder="Enter Location" />
  		<input type="text" required placeholder="Enter Total number of vacancy" />
  		<div>
  		<button type="button" className="mt-3">Post</button>
  	</div>
  	</form>
  </div>
  </div>
  
  
        </>
    )
}

export default AcedemiaPostjob;