import React, { useEffect } from "react";
import {Chart} from "chart.js";
import '../Pages/dashboard.css';
import DeiSidebar from "./DeiSidebar";

const Deidashboard = () => {
  useEffect(() => {
    const lineChartData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Sep", "Oct", "Nov", "Dec"],
      datasets: [{
        label: "Student",
        data: [100, 150, 200, 180, 250, 300, 350, 100, 250, 450, 200],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
      }]
    };

    const barChartData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Sep", "Oct", "Nov", "Dec"],
      datasets: [{
        label: "Jobs",
        data: [50, 80, 120, 90],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      }]
    };

    // Create and render charts
    const lineChartElement = document.getElementById("lineChart");
    new Chart(lineChartElement, {
      type: "line",
      data: lineChartData,
    });

    const barChartElement = document.getElementById("barChart");
    new Chart(barChartElement, {
      type: "bar",
      data: barChartData,
    });
  }, []);

  return (
   
      <>
      <DeiSidebar/>
      <div className="content">
      
        <div className="chart-container">
          <canvas id="lineChart"></canvas>
        </div>
        <div className="chart-container">
          <canvas id="barChart"></canvas>
        </div>
      </div>
      </>
  );
};

export default Deidashboard;