import React from 'react';
import '../CandidatePage/Chat.css';

import AcademiaSidebar from '../Components/AcademiaSidebar';

const students = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Jane Smith' },
  
];

const AcedemiaChat = ({ openChat }) => {
  return (
    <>
    <AcademiaSidebar/>
    <div className="container1">
      <h1>Student List</h1>
      <ul className="student-list">
        {students.map((student) => (
          <li className="student" key={student.id}>
            <span className="student-name">{student.name}</span>
            <button className="chat-btn" onClick={() => openChat(student.name)}>Chat</button>
          </li>
        ))}
      </ul>
    </div>
    </>
  );
};

export default AcedemiaChat;