import React from "react";

const Contact = ()=>{
    return(
        <>
        <div className="first_contact_div">
                <h2>Contact US</h2>
            </div>
            <div className="second_contact_div">
                <h4>Get in Touch</h4>
                <div className="contact_contain">
                    <input type="text" required placeholder="Full Name"/>
                    <input type="email" required placeholder="Enter Email"/>
                    <input type="tel" required placeholder="Enter Phone Numer"/>
                    <input className="contact_query" type="text" required placeholder="Enter Query"/>
                </div>
                <div className="button_div">
                    <button className="contact_button" >Send Query</button>
                </div>
            </div>
        </>
    )
}

export default Contact