import React from "react";
import researcher1 from "../images/research1.jpeg"
import researcher2 from "../images/research2.jpg"
import researcher3 from "../images/research3.webp"
import academia1 from "../images/acedemia1.jpg";
import academia2 from "../images/acedemia2.jpg";
import academia3 from "../images/acedemia3.jpeg";

const About = ()=>{
    const student = [{
        poster:researcher1,
        name: 'Dr. Elkasa Robert',
        info: 'He Accomplished his work field related to Mathematics'
    },
    {
        poster:researcher2,
        name: 'Dr. Elkasa Robert',
        info: 'He Accomplished his work field related to Mathematics'
    },
    {
        poster:researcher3,
        name: 'Dr. Elkasa Robert',
        info: 'He Accomplished his work field related to Mathematics'
    },
    {
        poster:researcher1,
        name: 'Dr. Elkasa Robert',
        info: 'He Accomplished his work field related to Mathematics'
    },
    {
        poster:researcher3,
        name: 'Dr. Elkasa Robert',
        info: 'He Accomplished his work field related to Mathematics'
    },
    ]

    const acedemia = [{
        poster: academia1,
        name:'University Of America'
    },
    {
        poster: academia2,
        name:'MIT, London'
    },
    {
        poster: academia3,
        name:'OxFord University'
    },
    {
        poster: academia1,
        name:'MIT, London'
    },
    {
        poster: academia1,
        name:'University Of America'
    },
]

    return(
        <>
        <div className="first_about_div">
                <h2>About US</h2>
            </div>
            <div className="second_about_div">
                <h3>Our Some Konwn Researcher</h3>
                {
                    student.map(item=>(
                        <div className="about_info">
                        <img src={item.poster} width={100} height={100}/>
                        <div><h6>{item.name}</h6></div>
                        <div className=""><h6>{item.info}</h6></div>
                        </div>
                    ))
                }
            </div>
            <div className="third_about_div">
                <h3>Our Some Partner Academia</h3>
                {
                    acedemia.map(item=>(
                        <div className="about_info">
                        <img src={item.poster} width={100} height={100}/>
                        <div><h6>{item.name}</h6></div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default About