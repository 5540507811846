import React from 'react';
import '../CandidatePage/Chat.css';
import AdminSidebar from './AdminSidebar';

const students = [
  { id: 1, name: 'Loukya Nagaraju' },
  { id: 2, name: 'Nandini Nidumolu' },
  { id: 3, name:'Gayatri Nulaka'},
  { id: 4, name: 'Sridharbabu Mullamuri' },
  
];

const removeHandler = (e,id)=>{
    console("remove the student",id,':','name');
}

const AdminCandidate = ({ openChat }) => {
  return (
    <>
    <AdminSidebar/>
    <div className="container-bookmark">
      <h1>Candidate List</h1>
      <ul className="student-list">
        {students.map((student) => (
          <li className="student" key={student.id}>
            <span className="student-name">{student.name}</span>
            <button className='btn ms-2 bg-primary' onClick={removeHandler}>Remove</button>
            <button className="chat-btn" onClick={() => openChat(student.name)}>Chat</button>
          </li>
        ))}
      </ul>
    </div>
    </>
  );
};

export default AdminCandidate;