import react from "react";
import { NavLink } from "react-router-dom";

const AdminSidebar = () =>{
    return(
        <div className="sidebar1">
        <ul className="sidebar-menu">
        <h1 class="head_dash">Admin Dashboard</h1>
                <li><NavLink to="/admin/dashboard">Home</NavLink></li>
                <li><NavLink to="/admin/acedemialist">Academic List</NavLink></li>
                <li><NavLink to="/admin/candidatelist">Candidates</NavLink></li>
                <li><NavLink to="/admin/recruiterlist">Recruiters</NavLink></li>
                <li><NavLink to="/admin/deilist">Dei Officers</NavLink></li>
            </ul>
            </div>
       
    )
}
export default AdminSidebar;