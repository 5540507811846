// src/components/TextBar.js
import React, { useState, useEffect } from 'react';

const IntervalText = ({ texts }) => {
  const [visibleTextIndex, setVisibleTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change the interval (in milliseconds) to control the time each text is visible

    return () => {
      clearInterval(interval);
    };
  }, [texts]);

  return (
    <div className="text-bar">
      {texts.map((text, index) => (
        <div
          key={index}
          className={`text-item ${index === visibleTextIndex ? 'visible' : 'hidden'}`}
        >
          {text}
        </div>
      ))}
    </div>
  );
};



export default IntervalText;
