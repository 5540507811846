import React, { useState } from 'react';
import '../CandidatePage/Chat.css';

const ChatModal = ({ username, closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');

  const handleSendMessage = () => {
    if (messageInput.trim() !== '') {
      setMessages((prevMessages) => [...prevMessages, { text: messageInput, sender: 'Me' }]);
      setMessageInput('');
    }
  };

  return (
    <div className="chat-popup">
      <div className="chat-container">
        <h2>Chat with {username}</h2>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div className={`message ${message.sender === 'Me' ? 'outgoing' : 'incoming'}`} key={index}>
              {message.text}
            </div>
          ))}
        </div>
        <textarea
          className="chat-input"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          placeholder="Type your message here..."
        />
        <button className="send-btn" onClick={handleSendMessage}>Send</button>
        <button className="close-btn" onClick={closeChat}>Close</button>
      </div>
    </div>
  );
};

export default ChatModal;

