import React from "react";
import CandidateSidebar from "../Components/CandidateSidebar";

const Profile = () =>{
    return(
        
        
        
       
    <div className="profile-container">
    <CandidateSidebar/>
      <div className="profile-sidebar">
        <div className="avatar">AC</div>
        <h1>Alex Carrey</h1>
        <p>Mathematical Researcher</p>
        <div className="divider"></div>
        <ul className="profile-details">
          <li>
            <span>Age:</span>
            <span>21</span>
          </li>
          <li>
            <span>Academia:</span>
            <span>ABC Institute</span>
          </li>
          <li>
            <span>Education:</span>
            <span>BSc in Computer Science</span>
          </li>
          <li>
            <span>Email:</span>
            <span>john.doe@example.com</span>
          </li>
          <li>
            <span>Phone:</span>
            <span>123-456-7890</span>
          </li>
          <li>
            <button className="edit-profile ">Edit Profile</button>
          </li>
        </ul>
      </div>

      <div className="profile-content">
        <h2>About Me</h2>
        <p>
          I am able to solve many of Mathematical Problem with in very limited time and can give you the proper explaination. It will help you in your Research 
        </p>
      </div>
    </div>
    
    )
} 

export default Profile