import react from "react";
import { NavLink } from "react-router-dom";

const DeiSidebar = () =>{
    return(
        <div className="sidebar1">
        <ul className="sidebar-menu">
        <h1 class="head_dash">DEI Officer Dashboard</h1>
                <li><NavLink to="/deiofficer/dashboard">Home</NavLink></li>
                <li><NavLink to="/deiofficer/posttraining">Training Session</NavLink></li>
                <li><NavLink to="/deiofficer/postseminar">Seminar</NavLink></li>
                <li><NavLink to="/deiofficer/bookmark">Bookmarks Student</NavLink></li>
                <li><NavLink to="/deiofficer/chat">Chat</NavLink></li>
            </ul>
            </div>
       
    )
}
export default DeiSidebar;