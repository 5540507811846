import React from "react";
import signup from '../images/signup.png';

const Recruitersign =()=>{
    return(
        <>
        <div>
           <form class="form_sign">
           	 <h2 class="first_sign_div">Recruiter Signup Page</h2>
               <img class="sign_img" src={signup} height="130px" width="150px"></img>
               <div >
                   <input type="text" required placeholder="Demographic Info"/>
                   <input type="text" required placeholder="Enter First Name"/>
               </div>
               <div >
                   <input type="text" required placeholder="Enter Last Name"/>
                   
               </div>
               <div >
                   <input  type="text" required placeholder="Contact"/>
                   <input  type="tel" required placeholder="Address"/>
               </div>
               <div>
                   <input type="email" required placeholder="Enter Email"/>
                   <input type='text'  required placeholder="Enter Password"/>
               </div>
                             
               <button class="sign_button" type="button">Register</button>
           </form>
       </div>
        </>
    )
}

export default Recruitersign