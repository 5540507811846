import React, { useState } from "react";
import CandidateSidebar from "../Components/CandidateSidebar";
import './Feedback.css';

const Feedback = () =>{
    const academias = [
        "Academia 1",
        "Academia 2",
        "Academia 3",
        // Add more academias to the list
      ];
    
      const [selectedAcademia, setSelectedAcademia] = useState("");
      const [feedback, setFeedback] = useState("");
    
      const handleAcademiaChange = (e) => {
        setSelectedAcademia(e.target.value);
      };
    
      const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Academia: ", selectedAcademia);
        console.log("Feedback: ", feedback);
      };
    return(
        <>
        <CandidateSidebar/>
        <div className="feedback-container">
      <h2>Give Feedback</h2>
      <form className="feedback-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="academia">Select Academia</label>
          <select
            id="academia"
            name="academia"
            value={selectedAcademia}
            onChange={handleAcademiaChange}
            required
          >
            <option value="">Select an Academia</option>
            {academias.map((academia, index) => (
              <option key={index} value={academia}>
                {academia}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="feedback">Feedback</label>
          <textarea
            id="feedback"
            name="feedback"
            value={feedback}
            onChange={handleFeedbackChange}
            rows={5}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
        </>
    )
}

export default Feedback;