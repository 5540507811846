import React from 'react';
import './Chat.css';
import CandidateSidebar from '../Components/CandidateSidebar';

const students = [
  { id: 1, name: 'University of London' },
  { id: 2, name: 'MIT' },
  
];

const Chat1 = ({ openChat }) => {
  return (
    <>
    <CandidateSidebar/>
    <div className="container1">
      <h1>Academia List</h1>
      <ul className="student-list">
        {students.map((student) => (
          <li className="student" key={student.id}>
            <span className="student-name">{student.name}</span>
            <button className="chat-btn" onClick={() => openChat(student.name)}>Chat</button>
          </li>
        ))}
      </ul>
    </div>
    </>
  );
};

export default Chat1;