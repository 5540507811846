import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router , Routes,Route} from 'react-router-dom';
import Header from './Components/Header';
import Home from './Pages/Home';
import Candidatesign from './Pages/Candidatesign.js';
import Academiasign from './Pages/Acedemiasign.js';
import Recruitersign from './Pages/Recruitersign';
import Deisign from './Pages/Deisign';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Login from './Pages/Login';
import { useState } from 'react';
import Profile from './Pages/Profile';
import Dashboard from './Pages/Dashboard';
import Search from './CandidatePage/Search';
import ApplyJob from './CandidatePage/ApplyJob';
import Job from './CandidatePage/Job';
import Feedback from './CandidatePage/Feedback';
import Chat from './CandidatePage/Chat';
import ChatPopup from './Components/ChatModal';
import FeedbackR from './RecruiterPage/FeedbackR';
import Application from './RecruiterPage/Application';
import Chat1 from './CandidatePage/Chat1';
import PostjobR from './RecruiterPage/PostjobR';
import Posttraining from './deiofficier/Posttraining';
import Postseminar from './deiofficier/Postseminar';
import Bookmark from './RecruiterPage/Bookmark';
import RecruiterDashboard from './RecruiterPage/RecruiterDashboard';
import ApplicationA from './CandidatePage/ApplicationA';
import AcedemiaDashboard from './Acedemia/AcedemiaDashboard';
import AcedemiaPostjob from './Acedemia/AcedemiaPostjob';
import AcedemiaFeedback from './Acedemia/AcedemiaBookmark';
import AcedemiaBookmark from './Acedemia/AcedemiaBookmark';
import AcedemiaChat from './Acedemia/AcedemiaChat';
import Deidashboard from './deiofficier/Deidashboard';
import Deibookmark from './deiofficier/Deibookmark';
import DeiChat from './deiofficier/DeiChat';
import AdminDashboard from './Admin/AdminDashboard';
import AdminCandidate from './Admin/AdminCandidatels';
import AdminAcedemia from './Admin/AdminAcedemials';
import AdminRecruiter from './Admin/AdminRecruiterls';
import Deilist from './Admin/Deilist';

function App() {
  const [showChat, setShowChat] = useState(false);
  const [chatUsername, setChatUsername] = useState('');

  const openChat = (username) => {
    setShowChat(true);
    setChatUsername(username);
  };

  const closeChat = () => {
    setShowChat(false);
    setChatUsername('');
  };

  return (
   <>
   <Router>
    <>
      <Header />
        <Routes>
           <Route path='/' element={<Home/>} /> 
           <Route path='/candidatesign' element={<Candidatesign/>} /> 
           <Route path='/acedemiasign' element={<Academiasign/>} /> 
           <Route path='/recruitersign' element={<Recruitersign/>} /> 
           <Route path='/deisign' element={<Deisign/>} />
           <Route path='/contact' element={<Contact/>} />
           <Route path='/about' element={<About/>} />
           <Route path='/login' element={<Login/>} />
           <Route path='/profile' element={<Profile/>}/>
           <Route path='/candidate/dashboard' element={<Dashboard/>}/>
           <Route path='/candidate/search' element={<Search/>}/>
           <Route path='/candidate/job' element={<Job/>}/>
           <Route path='/candidate/applyjob' element={<ApplyJob/>}/>
           <Route path='/candidate/feedback' element={<Feedback/>}/>
           <Route path='/candidate/chat' element={<Chat1 openChat={openChat}/> }/>
           <Route path='/recruiter/chat' element={<Chat openChat={openChat}/>}/>
           <Route path='/acedemia/chat' element={<AcedemiaChat openChat={openChat}/>}/>
           <Route path='/deiofficer/chat' element={<DeiChat openChat={openChat}/>}/>
           <Route path='/recruiter/feedback' element={<FeedbackR/>}/>
           <Route path='/recruiter/application' element={<Application/>}/>
           <Route path='/acedemia/application' element={<ApplicationA/>}/>
           <Route path='/recruiter/postjob' element={<PostjobR/>}/>
           <Route path='/acedemia/dashboard' element={<AcedemiaDashboard/>}/>
           <Route path='/acedemia/postjob' element={<AcedemiaPostjob/>}/>
           <Route path='/acedemia/bookmark' element={<AcedemiaBookmark/>}/>
           <Route path='/deiofficer/posttraining' element={<Posttraining/>}/>
           <Route path='/deiofficer/postseminar' element={<Postseminar/>}/>
           <Route path='/deiofficer/dashboard' element={<Deidashboard/>}/>
           <Route path='/deiofficer/bookmark' element={<Deibookmark/>}/>
           <Route path='/bookmark' element={<Bookmark openChat={openChat}/>}/>
           <Route path='/recruiter/dashboard' element={<RecruiterDashboard/>}/>
           <Route path='/admin/dashboard' element={<AdminDashboard/>}/>
           <Route path='/admin/acedemialist' element={<AdminAcedemia/>}/>
           <Route path='/admin/candidatelist' element={<AdminCandidate/>}/>
           <Route path='/admin/recruiterlist' element={<AdminRecruiter/>}/>
           <Route path='/admin/deilist' element={<Deilist/>}/>
        </Routes>
        {showChat && <ChatPopup username={chatUsername} closeChat={closeChat} />}

    </>
   </Router>
   </>
  );
}


export default App;
