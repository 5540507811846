import React from "react";
import "./CandidateCard.css";

const CandidateCard = ({ candidate, onAccept, onReject }) => {
  return (
    <div className="candidate-card">
      <h3>{candidate.name}</h3>
      <p>Email: {candidate.email}</p>
      <p>Phone: {candidate.phone}</p>
      <div className="buttons-container">
        <button className="accept-btn" onClick={() => onAccept(candidate.id)}>
          Accept
        </button>
        <button className="reject-btn" onClick={() => onReject(candidate.id)}>
          Reject
        </button>
      </div>
    </div>
  );
};

export default CandidateCard;