import React from "react";
import { Link, NavLink } from "react-router-dom";

const Login = ()=>{
    return(
        <>
        <div className="first_login_div">
                <h2>Login Page</h2>
            </div>
            <div className="login_div">
                <form className="form_login">
                    <h5 >URM Login</h5>
                    <div>
                        <input type="email" required placeholder="Email"/>
                    </div>
                    <div>
                        <input type="text" required placeholder="Password"/>
                    </div>
                    <div>
                        <button type="button" className="button_login">
                            Login
                        </button>
                    </div>
                     <h6>
                        <NavLink className="nav-link" to='/forgetpassword'>Forget Password</NavLink>
                            
                    </h6>               
                    <div className='login_option text-decoration-none'>
                    <p>New User?{' '}
                    {' '}Here</p>

                </div>
                </form>
        </div>
        </>
    )
}

export default Login