import React from "react";
import signup from '../images/signup.png';

const Academiasign =()=>{
    return(
        <>
        <div>
           <form class="form_sign">
           	 <h2 class="first_sign_div">Academia Signup Page</h2>
               <img class="sign_img" src={signup} height="130px" width="150px"></img>
               <div >
                   <input type="text" required placeholder="Institue Name "/>
                   <input type="text" required placeholder="Location "/>
               </div>
               <div >
                   <input  type="text" required placeholder="Nationalist"/>
                   <input  type="text" required placeholder="Field looking for"/>
               </div>
               <div>
                   <input type="email" required placeholder="Enter Email"/>
                   <input type='text'  required placeholder="Enter Password"/>
               </div>
               <div class="input_logo">
                    <div class="cv_input">
                        <label>Upload Logo</label>
                        <input type="file" accept="image/*" required /> 
                    </div>
               </div>
               
               <button class="sign_button" type="button">Register</button>
           </form>
       </div>
        </>
    )
}

export default Academiasign