import React, { useState } from "react"
import {  Button, HStack, Text } from "@chakra-ui/react"
import { Link } from "react-router-dom";
import acedemia1 from '../images/acedemia1.jpg'
import './Candidate.css';
import CandidateSidebar from "../Components/CandidateSidebar";


const Course = ({id,
opening,
academia_name,
poster,
total}) =>{
    return(
    <>   
    <div className="all_courses">
        <div className="container-course">
            <div className="row">
                <div className="col-lg-10 flex justify-content-between">
                        <div className="course_info_item d-flex justify-content-start ">
                            <img src={poster} alt="Image" width={120} height={94}/>
                            <div >
                                <div className="course_info_content">
                                <h2>{opening}</h2>
                                </div>
                                <div className="course_details d-flex align-items-center">    
                                    <div className="box">
                                    
                                        <div className="course_info_name">
                                            Academia Name : {academia_name}
                                        </div>
                                        <div className="course_info_desc">
                                        <p> Total : {total}</p>
                                        </div> 
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Link to={`/course/${id}`}>
                                            <Button  type="button" className="btn btn-warning ms-3">Apply</Button>
                                        </Link>
                                    
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
    </> 
    
     )
    }
    


const Search=()=>{
    const [keyword,setKeyword] = useState('');
    const [category,setCategory] = useState('');

    const academia =[{
        poster:acedemia1,
        opening:'Math Researcher',
        academia_name: 'Institute Of London',
        total:309
    }]
  

    return(<>
        <CandidateSidebar/>
        <div className="search">
        <h4 className="head_course">Openings</h4>
        <div className="filter ">
            <label>Filter</label>
            <select>
                <option>Select Preference</option>
                <option>By location</option>
                <option>By Academia</option>
                <option>By Deparment</option>
            </select>
        </div>
        <div className="course_search d-flex justify-content-start">
            <input type="text" placeholder="Search Here" value={keyword} onChange={(e)=>setKeyword(e.target.value)}/>
        </div>
        
        <div 
        >

           {
            academia.length >0 ? academia.map((item)=>(
                <Course key={item.academia_name}
                opening={item.opening}
                academia_name={item.academia_name}
                total={item.total}
                poster={item.poster}
                />
            )) : <h2 className="d-flex mt-5 align-item-center justify-content-center">Course Not Found</h2>
           }

        </div>
    
        </div>
    </>
    )
}
export default Search