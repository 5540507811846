import react from "react";
import { NavLink } from "react-router-dom";

const RecruiterSidebar = () =>{
    return(
        <div className="sidebar1">
        <ul className="sidebar-menu">
            <h1 class="head_dash">Recruiter Dashboard</h1>
                <li><NavLink to="/recruiter/dashboard">Home</NavLink></li>
                <li><NavLink to="/recruiter/postjob">Post Job</NavLink></li>
                <li><NavLink to="/recruiter/application">Applied Candidate</NavLink></li>
                <li><NavLink to="/recruiter/feedback">Communicate</NavLink></li>
                <li><NavLink to="/recruiter/chat">Chat</NavLink></li>
            </ul>
            </div>
       
    )
}
export default RecruiterSidebar;