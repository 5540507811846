import React, { useState } from "react";
import signup from '../images/signup.png'
import axios from "axios";

const Candidatesign =()=>{

    const [input,setInput] = useState({}) 

        const handleChange = (e) =>{
                const name = e.target.name;
                const value = e.target.value;
                setInput(values => ({...values,[name]:value}));
        }

        const handleSubmit =(e) =>{
            e.preventDefault();

            axios.post('http://localhost:80/api/candidate/save',input);
            console.log(input);
        }

    return(
        <>
        <div>
           <form className="form_sign" onSubmit={handleSubmit}>
           	 <h2  className="first_sign_div">Candidate Signup Page</h2>
               <img  className="sign_img" src={signup} height="130px" width="150px"></img>
               <div >
                   <input type="text" required name="fname" placeholder="Enter First Name" 
                    onChange={handleChange} />
                   <input type="text" required name="address" placeholder="Enter Address "
                   onChange={handleChange} />
               </div>
               <div >
                   <input  type="text" required name="dob" placeholder="Date Of Birth"
                   onChange={handleChange} />
                   <input  type="tel" required name="phone" placeholder="Enter Contact No."
                   onChange={handleChange} />
               </div>
               <div >
                   <input  type="text" required name="nationalist" placeholder="Nationalist"
                   onChange={handleChange} />
                   <input  type="text" required name="ethnicity" placeholder="Ethnicity" onChange={handleChange}
                   />
               </div>
               <div>
                   <input type="email" required name="email" placeholder="Enter Email" onChange={handleChange} />
                   <input type='password'  required name="password" placeholder="Enter Password" onChange={handleChange}/>
               </div>
               <div  className="input_ className">
                    <div  className="cv_input">
                        <label>Upload CV</label>
                        <input type="file" accept="application/pdf" name="cv" onChange={handleChange}/> 
                    </div>
                   
                    <div  className="cover_input">
                        <label>Cover Letter</label>
                        <input type="file" accept="application/pdf" name="cover" onChange={handleChange}/> 
                    </div>
               </div>
               
               <button  className="sign_button" type="submit">Register</button>
           </form>
       </div>
        </>
    )
}

export default Candidatesign