import React, { useState } from "react";
import CandidateSidebar from "../Components/CandidateSidebar";
import CandidateCard from "../RecruiterPage/CandidateCard";
import '../RecruiterPage/CandidateCard.css';
import AcademiaSidebar from "../Components/AcademiaSidebar";


const ApplicationA = () =>{
    const [candidates, setCandidates] = useState([
        {
          id: 1,
          name: "Acyuta Gopi",
          email: "acy.gopi@example.com",
          phone: "123-456-7890",
        },
        {
          id: 2,
          name: "Jane Smith",
          email: "jane.smith@example.com",
          phone: "987-654-3210",
        },
      ]);
    
      const handleAccept = (candidateId) => {
        console.log("Accepted candidate with ID:", candidateId);
      };
    
      const handleReject = (candidateId) => { 
        console.log("Rejected candidate with ID:", candidateId);
      }
    return(
        <>
        <AcademiaSidebar/>
        <div className="candidates-container">
        <h2>Candidates Applied</h2>
        {candidates.map((candidate) => (
          <CandidateCard
            key={candidate.id}
            candidate={candidate}
            onAccept={handleAccept}
            onReject={handleReject}
          />
        ))}
      </div>
        
        </>
    )
}

export default ApplicationA