import React from "react";
import { NavLink } from "react-router-dom";

const CandidateSidebar =()=>{
    return(
        
            <div className="sidebar1">
    <ul className="sidebar-menu">
        
            <h1 className="head_dash"> Candidate Dashboard</h1>
                <li><NavLink to="/candidate/dashboard">Home</NavLink></li>
                <li><NavLink to="/candidate/search">Search</NavLink></li>
                <li><NavLink to="/candidate/job">Apply Job</NavLink></li>
                <li><NavLink to="/candidate/feedback">Feedbacks</NavLink></li>
                <li><NavLink to="/candidate/chat">Chat</NavLink></li>
         
         {/*
            user  === "acedemia" && <>
            <h1 class="head_dash">Academia Dashboard</h1>
                <li><NavLink to="/dashboard">Home</NavLink></li>
                <li><NavLink to="/acedemia/postjob">Post Job</NavLink></li>
                <li><NavLink to="/acedemia/application">Applied Candidate</NavLink></li>
                <li><NavLink to="/bookmark">Bookmarks Student</NavLink></li>
                <li><NavLink to="/acedemia/chat">Chat</NavLink></li>
            </>
    */ }
         {/*
            user  === "deiofficier" && <>
           <h1 class="head_dash">DEI Officer Dashboard</h1>
                <li><NavLink to="/dashboard">Home</NavLink></li>
                <li><NavLink to="/deiofficier/posttraining">Training Session</NavLink></li>
                <li><NavLink to="/deiofficier/postseminar">Seminar</NavLink></li>
                <li><NavLink to="/bookmark">Bookmarks Student</NavLink></li>
                <li><NavLink to="/deiofficer/chat">Chat</NavLink></li>
            </>
*/}
      
    </ul>
 

      </div>  
    )
}

export default CandidateSidebar