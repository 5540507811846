import React from "react";
import { NavLink } from "react-router-dom";
import {RiDashboard2Fill} from "react-icons/ri"
import ASL_logo from '../images/URM.jpg';
import research1 from '../images/research1.jpeg'


const Header = ({isAuthenticated=false})=>{

    return(
        <>
        <nav className="navbar navbar-expand-lg ">
        <NavLink className="navbar-brand" to="/profile"><img className="ms-3" src={ASL_logo} alt="logo" width={50} height={35}/></NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto ">
    {
      isAuthenticated?(
        <>
        
          <li className="nav-item dropdown">
        <NavLink to="#services"><img className="img_profile" src={research1} height={35} width={40}/></NavLink>
        <ul className="dropdown-menu">
          <li className="nav-item">
      <h3 className="text-primary">Loukya Nagaraju</h3>
    </li>
   <li className="nav-item">
      <NavLink className="nav-link" to="/logout">LogOut</NavLink>
    </li>
        </ul>
      </li>
      <li className="nav-item">
      <NavLink className="nav-link" to="/profile">Profile</NavLink>
    </li>
     
        </>
      ):(
        <>
        <li className="nav-item dropdown">
        <NavLink to="#services">Register</NavLink>
        <ul className="dropdown-menu">
          <li className="nav-item">
      <NavLink className="nav-link" to="/candidatesign">Candidate</NavLink>
    </li>
   <li className="nav-item">
      <NavLink className="nav-link" to="/acedemiasign">Academia</NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="/recruitersign">Recruiter</NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="/deisign">DEI Officer</NavLink>
    </li>
        </ul>
      </li>
    <li className="nav-item">
      
      <NavLink className="nav-link" to='/login'>Login</NavLink>
                    
    </li>
        </>
      )
    }
     <li className="nav-item active">
      <NavLink className="nav-link" to="/">Home</NavLink>
    </li>
<li className="nav-item">
      <NavLink className="nav-link" to="/contact">Contact</NavLink>
    </li>
     <li className="nav-item">
      <NavLink className="nav-link" to="/about">About</NavLink>
    </li>
    <li className="nav-item">
      <NavLink className="nav-link" to="http://lxn5557.uta.cloud/blog/">Blog</NavLink>
    </li>

   
      </ul>
    
  </div>
</nav>
        
        </>
    )
}

export default Header