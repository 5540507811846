import React from "react";
import './Home.css';
import acedemia1 from '../images/acedemia1.jpg'
import acedemia2 from '../images/acedemia2.jpg'
import IntervalText from "../Components/IntervalText";
import { NavLink } from "react-router-dom";

const Home =()=>{
    const texts = [
        "This is the first text.",
        "This is the second text.",
        "This is the third text.",
      ];
    return(
        <>
       <div className="container">
      <img src={acedemia1} alt="Background Image 1" className="background-image1" />
      <img src={acedemia2} alt="Background Image 2" className="background-image2" />
      <div className="content1">
        <h5>This is an URM Application</h5>
        <h1 className="heading">We Help in Providing a list of underrepresented minority(URM) candidates to academia for PhD, studies, postdoc, and research</h1>
        <h5>For finding talent and opportunity</h5>
        <div className="home_button">
          <button type="button" className="button_home">
            <NavLink className="nav-link" to='/login'>Login</NavLink>
          </button>
        </div>
      </div>
    </div>
    <div>
      <IntervalText texts={texts} />
    </div>
    <div className="cards-section">
    <div className="card">
      <h3>Card 1</h3>
      <p>This is the content of Card 1.</p>
      <a href="#button1">Button 1</a>
    </div>

    <div className="card">
      <h3>Card 2</h3>
      <p>This is the content of Card 2.</p>
      <a href="#button2">Button 2</a>
    </div>

      <div className="card">
      <h3>Card 3</h3>
      <p>This is the content of Card 3.</p>
      <a href="#button2">Button 3</a>
    </div>

    {/* Add more cards as needed */}
  </div>
</>
        
    )
}

export default Home